import React from 'react';
import './ProductListTile.css';

const ProductListTile = ({ product }) => {
    return (
        <div className="product-tile">
            <img
                src={product.imageurl}
                alt={product.product_name}
            />
            <div className="product-info">
                <p>{product.brand}</p>
                <p>{product.product_name}</p>
                <p>{product.price}€</p>
                <p><small>{product.distance}</small></p>
            </div>
        </div>
    );
};
export default ProductListTile;