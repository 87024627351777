import React, { useState } from 'react';
import ProductListTile from './ProductListTile';
import './ClothesSearch.css'; // Assuming you'll add the spinner styles here

const ClothesSearch = () => {
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Image');

    // just to deside wich embeding to use
    const handleSwitch = () => {
        // rest the selected imag and response data
        setSelectedOption((prev) => (prev === 'Image' ? 'GPT' : 'Image'));
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            alert("Please select a file first!");
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = async () => {
            const base64String = reader.result.split(',')[1];
            const imageType = file.type.split('/')[1];
            //  34.159.246.50
            const serviceUrl = selectedOption === 'Image' ? 'https://api.texognize.com:3000/knn_img' : 'https://api.texognize.com:3000/knn_txt'; //'http://34.159.246.50:3000/knn_txt';

            try {
                setLoading(true); // Start loading
                console.log("call server")
                const response = await fetch(serviceUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ image: base64String, k: 10, imageType: imageType}),
                });
                console.log("response")
                // log http code
                console.log(response.status)
                const data = await response.json();
                console.log("data: ", data)
                setResponseData(data);
            } catch (error) {
                console.error("Error uploading the image:", error);
            } finally {
                setLoading(false); // End loading
            }
        };

        reader.onerror = () => {
            console.error("Error reading file");
        };
    };

    return (
        <div>
            <div className="top-right-container">
                <label className="switch">
                    <input type="checkbox" onChange={handleSwitch}/>
                    <span className="slider round"></span>
                </label>
                <div className="label">
                    {selectedOption}
                </div>
            </div>
            <input type="file" accept="image/png, image/jpeg" onChange={handleFileChange}/>
            <hr/>
            {loading && <div className="bar-loading"></div>} {/* Render spinner if loading */}

            {responseData.length > 0 && <h3>Response Data</h3>}

            <div>
                {responseData.map((product, index) => (
                    <ProductListTile key={index} product={product}/>
                ))}
            </div>
        </div>
    );
};

export default ClothesSearch;