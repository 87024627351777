import * as React from "react"
const SvgLogo = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2,
        }}
        viewBox="0 0 192 130"
        {...props}
    >
        <path
            d="M1765.15 1283h-38.45v-3.98h1.38c2.65 0 4.53-.28 5.62-.83 1.1-.55 1.65-1.55 1.65-3.02 0-1.46-.36-3.06-1.1-4.8-.73-1.74-2.01-4.03-3.84-6.87l-8.65-13.59-4.81-8.24-2.19-2.33-20.46-30.76c-2.2-3.21-4.01-5.66-5.43-7.35-1.42-1.69-2.97-2.84-4.67-3.43-1.69-.6-4.14-.9-7.34-.9v-4.11h38.17v4.11h-1.51c-2.56 0-4.39.3-5.49.9-1.1.59-1.65 1.62-1.65 3.09 0 1.1.44 2.42 1.3 3.98.87 1.55 1.95 3.29 3.23 5.22 1.28 1.92 2.47 3.98 3.57 6.18l8.52 14.28 2.05 2.19 25.96 38.04c2.29 3.3 4.37 5.47 6.24 6.52 1.88 1.05 4.51 1.63 7.9 1.72v3.98Zm-60.01 0h-30.34v-3.98c2.47-.09 4.48-.44 6.04-1.03 1.56-.6 3.09-1.67 4.6-3.23 1.51-1.56 3.36-3.75 5.56-6.59l23.89-31.44 4.53 6.31h-1.92l-16.61 23.48c-.92 1.19-1.9 2.61-2.96 4.26-1.05 1.65-1.57 3.11-1.57 4.39 0 1.19.54 2.13 1.64 2.82 1.1.68 3.48 1.03 7.14 1.03v3.98Zm39.83-73.87-20.05 26.08-4.26-5.9h1.79l14.41-20.73a74.79 74.79 0 0 0 2.68-4.81c.69-1.37 1.03-2.61 1.03-3.71 0-1-.53-1.78-1.58-2.33s-3.22-.83-6.52-.83v-4.11h31.58v4.11c-2.93.1-5.33.46-7.21 1.1-1.87.64-3.68 1.81-5.42 3.51-1.74 1.69-3.89 4.23-6.45 7.62Z"
            style={{
                fill: "#fff",
                fillRule: "nonzero",
            }}
            transform="translate(-1649 -1171.166)"
        />
        <path
            d="M1679.79 1245v-14.1h-5.27v-1.89h12.68v1.89h-5.29v14.1h-2.12ZM1689.41 1245v-15.99h11.56v1.89h-9.44v4.9h8.84v1.87h-8.84v5.44h9.81v1.89h-11.93Z"
            style={{
                fill: "#fff",
                fillRule: "nonzero",
            }}
            transform="translate(-1652 -1170)"
        />
        <path
            d="M1675.08 1237.21c0-2.65.71-4.73 2.14-6.23 1.42-1.5 3.26-2.25 5.52-2.25 1.47 0 2.8.35 3.99 1.06 1.18.7 2.09 1.68 2.71 2.95.62 1.26.93 2.69.93 4.29 0 1.62-.33 3.07-.98 4.35-.65 1.28-1.58 2.25-2.78 2.91-1.2.65-2.5.98-3.88.98-1.51 0-2.85-.36-4.04-1.09a7.049 7.049 0 0 1-2.69-2.98 8.851 8.851 0 0 1-.92-3.99Zm2.18.04c0 1.92.52 3.44 1.56 4.55 1.03 1.11 2.33 1.66 3.89 1.66 1.6 0 2.91-.56 3.94-1.68 1.02-1.12 1.54-2.71 1.54-4.76 0-1.31-.22-2.44-.66-3.41-.44-.97-1.08-1.73-1.93-2.26-.85-.53-1.8-.8-2.85-.8-1.5 0-2.79.51-3.87 1.54s-1.62 2.75-1.62 5.16ZM1700.58 1238.73v-1.88l6.77-.01v5.94c-1.04.82-2.11 1.45-3.22 1.87-1.1.41-2.24.62-3.4.62-1.57 0-2.99-.33-4.28-1.01-1.28-.67-2.25-1.64-2.9-2.91-.66-1.28-.99-2.7-.99-4.27 0-1.55.33-3.01.98-4.35.65-1.35 1.59-2.36 2.81-3.01 1.22-.65 2.63-.98 4.22-.98 1.16 0 2.2.18 3.13.56.94.37 1.67.9 2.2 1.57.53.66.94 1.54 1.21 2.61l-1.91.53c-.24-.82-.53-1.46-.89-1.92-.36-.47-.87-.84-1.53-1.12-.66-.28-1.39-.42-2.2-.42-.97 0-1.8.15-2.51.44-.7.3-1.27.68-1.71 1.16-.43.48-.76 1.01-1 1.58-.41.99-.62 2.07-.62 3.22 0 1.43.25 2.62.74 3.58s1.21 1.67 2.14 2.14c.94.46 1.94.69 2.99.69.92 0 1.81-.17 2.69-.52.87-.36 1.53-.73 1.98-1.13v-2.98h-4.7ZM1710.45 1245v-15.99h2.17l8.4 12.55v-12.55h2.03V1245h-2.17l-8.4-12.56V1245h-2.03ZM1726.96 1229.01h2.116v15.989h-2.116zM1731.53 1245v-1.96l8.19-10.24c.59-.73 1.14-1.36 1.66-1.9h-8.92v-1.89h11.45v1.89l-8.98 11.09-.96 1.12h10.2v1.89h-12.64Z"
            style={{
                fill: "#fff",
                fillRule: "nonzero",
            }}
            transform="translate(-1587.933 -1170.273)"
        />
        <path
            d="m1812.11 1236.7 9.45 6.62c.18.12.26.35.19.56-.07.21-.26.35-.48.35h-10c-.27 0-.5-.23-.5-.5v-15c0-.28.23-.5.5-.5h10c.22 0 .4.13.47.33.08.2.01.43-.15.56 0 0-7.77 6.21-9.48 7.58Zm-.34-1.01 8.08-6.46h-8.08v6.46Zm0 2v5.54h7.92l-7.92-5.54Z"
            style={{
                fill: "#fff",
            }}
            transform="translate(-1652 -1170)"
        />
        <path
            d="M1821.27 1236.23c.28 0 .5.22.5.5 0 .27-.22.5-.5.5h-10c-.27 0-.5-.23-.5-.5 0-.28.23-.5.5-.5h10ZM1652 1229h8v63h117v8h-125v-71Z"
            style={{
                fill: "#fff",
            }}
            transform="translate(-1652 -1170)"
        />
        <path
            d="M1652 1229h8v63h117v8h-125v-71Z"
            style={{
                fill: "#fff",
            }}
            transform="rotate(-180 922 650)"
        />
        <circle
            cx={1812.27}
            cy={1230.73}
            r={2.273}
            style={{
                fill: "#fff",
            }}
            transform="translate(-1653 -1172)"
        />
        <circle
            cx={1812.27}
            cy={1230.73}
            r={2.273}
            style={{
                fill: "#fff",
            }}
            transform="translate(-1643 -1172)"
        />
        <circle
            cx={1812.27}
            cy={1230.73}
            r={2.273}
            style={{
                fill: "#fff",
            }}
            transform="translate(-1653 -1164)"
        />
        <circle
            cx={1812.27}
            cy={1230.73}
            r={2.273}
            style={{
                fill: "#fff",
            }}
            transform="translate(-1643 -1164)"
        />
        <circle
            cx={1812.27}
            cy={1230.73}
            r={2.273}
            style={{
                fill: "#fff",
            }}
            transform="translate(-1653 -1157)"
        />
        <circle
            cx={1812.27}
            cy={1230.73}
            r={2.273}
            style={{
                fill: "#fff",
            }}
            transform="translate(-1643 -1157)"
        />
    </svg>
)
export default SvgLogo
