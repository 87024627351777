import './App.css';
import './logo';
import ClothesSearch from "./Components/ClothesSearch";
import React from "react";
import SvgLogo from "./logo";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <SvgLogo style={{ height: '150px', padding: '50'}}/>
                <ClothesSearch/>
            </header>
        </div>
    );
}

export default App;
